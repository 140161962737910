<template>
  <div class="big" v-title data-title="Online Games on Gugo ——Let's play">
    <div>
      <Content></Content>
    </div>
    <BottomNav portal="gugoplay_pc_home"></BottomNav>
  </div>
</template>

<script>
import Content from '@/components/HomeIndex/Content';
import BottomNav from '@/components/BottomNav';
import {clickGameLog, determinePcOrMove, getJson, pageInitLog, pageOutLog, recentGame} from '@/utils/utils.js'
export default {
  name: "HomeIndex",
  components: {
    Content,
    BottomNav
  },
  data() {
    return {

    }
  },
  created() {

  },
  mounted() {
    if (determinePcOrMove() == 1) {
      let { channel } = this.$route.query
      this.$router.push({
        path: '/M/homeIndex',
        query: {
          channel
        }
      },()=>{})
    } else {
      // 进入页面埋点
      pageInitLog('gugoplay_pc_home')
      this.getJson()
      document.title = 'Online Games on Gugo ——Let\'s play'
    }
  },
  methods: {
    getJson() {
      let jsonArr = getJson()
      let allGameList = []
      jsonArr && jsonArr.map((item)=>{
        allGameList.push(item)
      })
    }
  },
  beforeRouteLeave(to, from, next){
    console.log(to);
    console.log(from);
    if (to.query.gameId) {
      getJson().map((item)=>{
        if (item.gameId == to.query.gameId) {
          clickGameLog('gugoplay_pc_home', item)
          recentGame(item)
        }
      })
    }
    next()
  },
  beforeDestroy() {
    console.log(33333);
    // 离开页面埋点
    pageOutLog('gugoplay_pc_home')
  }
}
</script>

<style lang="less" scoped>
.big{
  //height: 100vh;
  //overflow-y: auto;
}
</style>
